import React from 'react';
import { AllEventsPageResponse } from '../../hooks';

import Lock from '../../Components/svgs/Lock';
import World from '../../Components/svgs/World';
import { formatNumber, formatShortDate } from '../../utils';
import { useNavigate } from 'react-router-dom';

type Props = {
    lastIndex: boolean;
} & AllEventsPageResponse;

export const EventCard: React.FC<Props> = (props) => {
    const navigate = useNavigate();


    return (
        <div className='row eventCard' onClick={ () => navigate(`/events/${props.id}`) }>
            <div className='col'>
                <div className='name'>
                    <img src={ props.banner } alt='banner' className='event-banner' />
                    <div className='event-name'>{ props.title }</div>
                </div>

                <div className='visibility'>

                    <div className='icon'>
                        { 
                            props.visibility === 'Public' ?
                                <World />
                            :
                            <Lock className='lock' />
                        }
                    </div>

                    <div className='text'>
                        { props.visibility === 'Public' ? 'Public' : 'Private' }
                    </div>
                </div>

                <div className='view'>{ formatNumber(props.views) }</div>

                <div className='date-created'>{ formatShortDate( new Date(props.dateCreated) ) }</div>

                <div className='event-date'>{ formatShortDate( new Date(props.eventDate.startDate) ) } - { formatShortDate( new Date(props.eventDate.endDate) ) }</div>
            </div>

            { !props.lastIndex && <div className='divider' /> }
        </div>
    )
}
