import './index.scss';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../Logo';
import Home from '../../../svgs/Home';
import Party from '../../../svgs/Party';
import Billing from '../../../svgs/Billing';
import Settings from '../../../svgs/Settings';
import SignOut from '../../../svgs/SignOut';

type MainActive = '/home'|'/events'|'/billing'|'/settings';

const MainDashBoardNavBar : React.FC = () => {

    const navigate = useNavigate();
    const [ active ] = useState<MainActive>( window.location.pathname as MainActive );

    return (
        <div className='navbar main dashboard-nav'>
            <Logo onClick={ () => navigate('/home') } />

            <div className='underline' />

            <div className='menu-container'>
                <div className='items-wrapper'>
                    <div className={`menu-item ${ active === '/home' ? 'active box-shadow' : '' }`} onClick={ () => navigate('/home') }>
                        <div className='icon-wrapper box-shadow'>
                            <Home />
                        </div>

                        <div className='menu-title'>Dashboard</div>
                    </div>

                    <div className={`menu-item ${ active === '/events' ? 'active box-shadow' : '' }`} onClick={ () => navigate('/events') }>
                        <div className='icon-wrapper box-shadow'>
                            <Party />
                        </div>

                        <div className='menu-title'>Events</div>
                    </div>

                    <div className={`menu-item ${ active === '/billing' ? 'active box-shadow' : '' }`} onClick={ () => navigate('/billing') }>
                        <div className='icon-wrapper box-shadow'>
                            <Billing fill='' className='billing' />
                        </div>

                        <div className='menu-title'>Billing</div>
                    </div>
                </div>

                <div className='items-wrapper'>
                    <div className='title'>Account</div>

                    <div className={`menu-item ${ active === '/settings' ? 'active box-shadow' : '' }`} onClick={ () => navigate('/settings') }>
                        <div className='icon-wrapper box-shadow'>
                            <Settings className='settings' />
                        </div>

                        <div className='menu-title'>Settings</div>
                    </div>

                    <div className='menu-item sign-out'>
                        <div className='icon-wrapper box-shadow' onClick={ () => navigate('/sign/out') }>
                            <SignOut />
                        </div>

                        <div className='menu-title'>Sign Out</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainDashBoardNavBar;
