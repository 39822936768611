import React from 'react';

type BillingProps = {
    fill: string;
    className?: string;
}

const Billing: React.FC<BillingProps> = ({ fill, className }) => {
    return (
        <svg width="800px" height="800px" className={className} viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="full" enable-background="new 0 0 76.00 76.00" xmlSpace="preserve">
            <path fill={fill} fill-opacity="0.603922" strokeLinejoin="round" d="M 58,38C 58,26.9543 49.0457,18 38,18L 38,24C 45.732,24 52,30.268 52,38L 58,38 Z "/>
            <path fill={fill} fill-opacity="0.301961" strokeLinejoin="round" d="M 58,38L 52,38C 52,41.866 50.433,45.366 47.8995,47.8995L 52.1421,52.1421C 55.7614,48.5229 58,43.5229 58,38 Z "/>
            <path fill={fill} fill-opacity="1" strokeLinejoin="round" d="M 47.8995,47.8995L 52.1421,52.1421C 48.5229,55.7614 43.5228,58 38,58C 26.9543,58 18,49.0457 18,38C 18,26.9543 26.9543,18 38,18L 38,24C 30.268,24 24,30.268 24,38C 24,45.732 30.268,52 38,52C 41.866,52 45.366,50.433 47.8995,47.8995 Z M 38.92,46.1787L 38.92,48.65L 37.24,48.65L 37.24,46.25C 35.6075,46.2425 34.0875,45.8838 32.68,45.1738L 32.68,41.93C 33.1475,42.325 33.8431,42.6913 34.7669,43.0288C 35.6906,43.3663 36.515,43.56 37.24,43.61L 37.24,39.1813C 35.39,38.5038 34.1275,37.7688 33.4525,36.9763C 32.7775,36.1838 32.44,35.2188 32.44,34.0813C 32.44,32.8563 32.8787,31.8131 33.7562,30.9519C 34.6337,30.0906 35.795,29.59 37.24,29.45L 37.24,27.29L 38.92,27.29L 38.92,29.4013C 40.5275,29.4788 41.7275,29.74 42.52,30.185L 42.52,33.29C 41.4525,32.635 40.2525,32.235 38.92,32.09L 38.92,36.6613C 40.6325,37.2738 41.8606,37.9744 42.6044,38.7631C 43.3481,39.5519 43.72,40.505 43.72,41.6225C 43.72,42.9125 43.3056,43.9519 42.4769,44.7406C 41.6481,45.5294 40.4625,46.0088 38.92,46.1787 Z M 37.24,35.9713L 37.24,32.1388C 36.12,32.3413 35.56,32.925 35.56,33.89C 35.56,34.7225 36.12,35.4163 37.24,35.9713 Z M 38.92,39.8525L 38.92,43.565C 40.04,43.385 40.6,42.8013 40.6,41.8138C 40.6,41.0138 40.04,40.36 38.92,39.8525 Z "/>
        </svg>
    )
}

export default Billing;
