import { useState, useEffect } from 'react';

import { 
    getOrganizerSettingsPage, OrganizerSettingsPageUser, saveOrganizerSettings, OrganizerSettingsUpdateResponse, changeOrganizerPassword, OrganizerSettingsPageOrganizer, 
    OrganizerSettingsPageTeams, OrganizerSettingsPage, fetchGraphQl, getBannerUploadApi, getImageUploadApi, removeTeamMember, updateTeamMemberTitle, addTeamMember,
    resendOrganizerTeamInviteToken, saveOrganizerMemberSettings, changeOrganizerMemberPassword, OrganizerSettingsMemberUpdateResponse, reLogin
} from '../../utils';

import { UserTitle } from '../../Components/NavBar/DashBoard/Settings';

export const useSettings = ( ) => {
    const [ isOrg, setIsOrg ] = useState<boolean>(false);

    const [ loading, setLoading ] = useState<boolean>(false);

    const [ user, setUser ] = useState<OrganizerSettingsPageUser>({
        id: '',
        name: '',
        email: '',
        phoneNumber: '',
        profilePicture: '',
        title: 'Admin'
    });

    const [ organizer, setOrganizer ] = useState<OrganizerSettingsPageOrganizer>({
        id: '',
        email: '',
        banner: '',
        location: '',
        orgName: '',
        phoneNumber: '',
        profilePicture: ''
    });

    const [ teams, setTeams ] = useState<OrganizerSettingsPageTeams[]>([]);

    const [ pendingTeams, setPendingTeams ] = useState<OrganizerSettingsPageTeams[]>([]);

    const fetchData = async ( token: string ) => {
        setLoading(true);

        let res = await fetchGraphQl( getOrganizerSettingsPage, { token } );

        if ( res.errors ) {
            console.log( res.errors );

            return;
        }

        let data: OrganizerSettingsPage = res.data.getOrganizerSettingsPage;

        setIsOrg( data.isOrg );
        setUser( data.user );
        setOrganizer( data.organizer );

        setTeams( data.teams.accepted );
        setPendingTeams( data.teams.pending );
        
        setLoading( false );
    }

    return {
        fetchData,

        isOrg,
        loading,

        user,
        organizer,

        teams,

        editOrganizer: ( org: {  orgName?: string, email?: string, phoneNumber?: string, location?: string, profilePicture?: string, banner?: string } ) => {
            
            setOrganizer({ 
                ...organizer,
                orgName: org.orgName || organizer.orgName,
                email: org.email || organizer.email,
                phoneNumber: org.phoneNumber || organizer.phoneNumber,
                location: org.location || organizer.location,
                profilePicture: org.profilePicture || organizer.profilePicture,
                banner: org.banner || organizer.banner
            });
        },
        
        editMember: ( member: {  name?: string, email?: string, phoneNumber?: string, profilePicture?: string } ) => {
            
            setUser({ 
                ...user,
                name: member.name ||  user.name,
                email: member.email || user.email,
                phoneNumber: member.phoneNumber || user.phoneNumber,
                profilePicture: member.profilePicture || user.profilePicture,
            });

        },

        saveOrganizerSettings: async ( token: string ) => {
            setLoading( true );

            let res = await fetchGraphQl( saveOrganizerSettings, { token, args: organizer });

            if ( res.errors ) {
                setLoading( false );
                return console.log( res.errors[0].message );
            }

            let data : OrganizerSettingsUpdateResponse = res.data.saveOrganizerSettings;

            if ( data.error ) {
                setOrganizer( data.organizer ); // Returns to default before fetching
                setLoading( false );
                return console.log( data.error.message );
            }

            setLoading( false );
            console.log( 'Organizer settings saved successfully' );
        },

        saveOrganizerMemberSettings: async ( token: string ) => {
            setLoading( true );

            let res = await fetchGraphQl( saveOrganizerMemberSettings, { token, args: { ...user, title: undefined } });

            if ( res.errors ) {
                setLoading( false );
                return console.log( res.errors[0].message );
            }

            let data : OrganizerSettingsMemberUpdateResponse = res.data.saveOrganizerMemberSettings;

            if ( data.error ) {
                setUser( data.member ); // Returns to default before fetching
                setLoading( false );
                return console.log( data.error.message );
            }

            setLoading( false );
            console.log( 'Organizer Member settings saved successfully' );
        },

        changeOrganizerPassword: async ( token: string ) => {
            setLoading( true );

            let res = await fetchGraphQl( changeOrganizerPassword, { token });

            if ( res.errors ) {
                setLoading( false );
                return console.log( res.errors[0].message );
            }

            console.log( 'Organizer password changed request sent successfully' );
        },

        changeMemberPassword: async ( token: string ) => {
            setLoading( true );

            let res = await fetchGraphQl( changeOrganizerMemberPassword, { token });
            
            if ( res.errors ) {
                setLoading( false );
                return console.log( res.errors[0].message );
            }

            console.log( 'Organizer Team Member password changed request sent successfully' )
        },

        uploadBanner: async ( token: string, img: File ) => {
            const form = new FormData();

            form.append("image", img);
            form.append("token", token);

            const req = await fetch( getBannerUploadApi(), { method: "POST", body: form });

            let result = await req.json();

            if ( result.error ) {
                console.log("Error uploading: " + result.error );
            }

            if ( result.url ) setOrganizer({...organizer, banner: result.url });

            console.log("Banner uploaded successfully: " + result.url );
        },

        uploadProfilePicture: async ( token: string, img: File, type: 'org' | 'member' = 'org' ) => {
            const form = new FormData();

            form.append("image", img);
            form.append("token", token);
            form.append("craving", type)

            const req = await fetch( getImageUploadApi(), { method: "POST", body: form });

            let result = await req.json();

            if ( result.error ) {
                console.log("Error uploading: " + result.error );
            }

            if ( result.url ) {
                if ( type === 'org' ) setOrganizer({...organizer, profilePicture: result.url });
                else setUser({...user, profilePicture: result.url });  // Update user profile picture as well if editing member settings
            }

            console.log("Profile picture uploaded successfully: " + result.url );
        },

        deleteMember: async ( id: string, token: string ) => {
            setLoading( true );

            let res = await fetchGraphQl(removeTeamMember, { teamMemberId: id, token});

            if ( res.errors ) {
                setLoading(false)
                return alert('Failed to Remove Member')
            }

            setTeams( ( prev ) => prev.filter( t => t.id !== id ));

            setLoading( false );
        },

        updateMember: async ( id: string, token: string, type: UserTitle ) => {
            setLoading( true );

            let res = await fetchGraphQl(updateTeamMemberTitle, { newTitle: type, teamMemberId: id, token });

            if ( res.errors ) {
                setLoading(false)
                return alert('Failed to Remove Member');
            }

            setTeams( ( prev ) => {
                let index = prev.findIndex( t => t.id === id );

                if ( index  === -1 ) return prev;

                let newData = prev.map( t => t.id === id ? {...t, type } : t );

                return newData
            } );

            setLoading( false );
        },

        pendingTeams,

        createTeamMember: async ( token: string, name: string, email: string, phoneNumber: string, role: string ) => {
            setLoading( true );

            let res = await fetchGraphQl( addTeamMember, { token, args: { name, email, phoneNumber, role } });

            if ( res.errors ) {
                setLoading(false)
                return alert('Failed to Add Member');
            }
            
            setPendingTeams( 
                prev => {
                    return [
                        ...prev,
                        {
                            id: res.data.addTeamMember,
                            joinedDate: Date.now(),
                            name,
                            email,
                            phoneNumber,
                            type: role as UserTitle,
                            profilePicture: '/home-header.png'
                        }
                    ]
                }
            );
            
            setLoading( false );
        },

        resendMemberInvitation: async ( token: string, orgTeamId: string ) => {
            setLoading( true );

            let res = await fetchGraphQl( resendOrganizerTeamInviteToken, { token, orgTeamId });

            if ( res.errors ) {
                setLoading(false)
                return alert('Failed to Resend Invitation');
            }

            console.log( res.data.resendOrganizerTeamInviteToken );

        }
    }
}
