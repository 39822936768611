import React, { useState } from 'react';

import Trashcan from '../../Components/svgs/Trashcan';
import ArrowLeft from '../../Components/svgs/ArrowLeft';
import { UserTitle } from '../../Components/NavBar/DashBoard/Settings';
import Resend from '../../Components/svgs/Send';

import { formatEventDate, OrganizerSettingsPageTeams } from '../../utils';

type TeamDataProps = {
    deleteMember: ( ) => void;
    changeAccountType: ( type: UserTitle ) => void;
    pending?: boolean;
    isSession: boolean;
    resendMemberInvitation?: () => void;
} & OrganizerSettingsPageTeams;


export const TeamData: React.FC<TeamDataProps> = ({ id, name, type, email, profilePicture, joinedDate, deleteMember, changeAccountType, pending, isSession, resendMemberInvitation }) => {
    let dateFormat = formatEventDate(new Date(joinedDate));
    let [ accountType, setAccountType ] = useState<boolean>( false );

    if (dateFormat.indexOf('@') !== -1) {
        dateFormat = dateFormat.substring(0, dateFormat.indexOf('@') - 1) + ', ' + (new Date(joinedDate).getFullYear());
    }

    const updateMember = async ( type: UserTitle ) => {
        changeAccountType( type );
        setAccountType( false );
    }

    return (
        <div className='team-data'>
            <div className='th'>
                <img src={profilePicture} className='profile' alt='profile' />

                <div className='name'>{name}</div>

                {
                    pending &&
                    <div className='resend' title="Resend Invitation" onClick={resendMemberInvitation} >
                        <Resend className='icon'/>
                    </div>
                }
                
                { type !== 'Organizer' && !isSession &&
                    <div className='trash' onClick={deleteMember} title='Delete Member'>
                        <Trashcan className='icon' />
                    </div>
                }

            </div>
            
            <div className='th'>{email}</div>
            
            <div className='th'>
                <div className={`accountType ${ accountType === true ? 'active' : '' }`}>{type}</div>

                { type !== 'Organizer' && <ArrowLeft className={`icon arrowLeft ${ accountType === true ? 'active' : '' }`} onClick={ () => setAccountType( !accountType ) } /> }
                
                {
                    accountType && type !== 'Organizer' && (
                        <div className='options-container'>
                            <div className='background box-shadow' onClick={ () => setAccountType(false) } />
                            <div className='options box-shadow'>
                                <div className='opt' onClick={ async () => await updateMember("Admin")}>Admin</div>
                                <div className='opt' onClick={ async () => await updateMember("Member")}>Member</div>
                                <div className='opt' onClick={ async () => await updateMember("Guest")}>Guest</div>
                            </div>
                        </div>
                    )
                }
            </div>
            
            <div className='th'>{dateFormat}</div>
        </div>
    );
}
