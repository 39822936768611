import React, { useRef, useState } from 'react';

type ImageUploaderProps = {
    children?: React.ReactNode;
    preview: React.ReactNode;
    handleImageUpload: ( image: File ) => Promise<void>;
    clickable: boolean;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ children, preview, handleImageUpload, clickable }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Trigger file input click on div click
    const handleClick = () => {
        clickable && fileInputRef.current?.click();
    };

    const handleInputImageChange = async () => {
        if ( fileInputRef.current ) {
            const image = fileInputRef.current.files![0];

            if ( image ) await handleImageUpload( image );
        }
    }

    return (
        <div className="image-uploader" onClick={handleClick}>
            {preview}

            <input
                type="file"
                accept=".png, .jpeg, .jpg, .svg"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleInputImageChange}
            />
            {children}
        </div>
    )
}

export default ImageUploader;
