import './index.scss';

import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import Logo from '../../../Logo';
import ArrowLeft from '../../../svgs/ArrowLeft';
import Teams from '../../../svgs/Teams';

type SettingsActive = 'org' | 'teams' | 'profile';
export type UserTitle = "Admin" | "Member" | "Guest";

export type SettingsDashBoardNavBarUsersProps = {
    name: string;
    picture: string;
    banner: string;
    id: string;
    editable: boolean;
    uploadProfilePicture: ( img: File ) => Promise<void>;
    changeMemberPassword: () => void;
    editMember: ( member: {  name?: string, email?: string, phoneNumber?: string, profilePicture?: string } ) => void;
    saveOrganizerMemberSettings: () => void;
}

export type SettingsDashBoardNavBarOrgProps = {
    name: string;
    picture: string;
    banner: string;
    editable: boolean;
    editOrganizer: ( org: {  orgName?: string, email?: string, phoneNumber?: string, location?: string, profilePicture?: string, banner?: string } ) => void;
    saveOrganizerSettings: () => void;
    changeOrganizerPassword: () => void;
    uploadBanner: ( img: File ) => Promise<void>;
    uploadProfilePicture: ( img: File ) => Promise<void>;
}

export type SettingsDashBoardNavBarProps<P=any, O=any> = {
    profile: SettingsDashBoardNavBarUsersProps & P;

    organizer: SettingsDashBoardNavBarOrgProps & O;
}

type Props = {
    settings: SettingsDashBoardNavBarProps;
    setPage: ( page: SettingsActive ) => void;
}

const SettingsDashBoardNavBar : React.FC<Props> = ({ settings, setPage }) => {
    const navigate =  useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams( location.search );

    const [ active, setActive ] = useState<SettingsActive>( ( queryParams.get("page") as SettingsActive ) || "org" );

    const { profile, organizer } = settings;

    useEffect( () => {
        setPage( active );
    }, [ active ]);
    
    return (
        <div className='navbar settings dashboard-nav'>
            <Logo onClick={ () => navigate('/home') }/>

            <div className='underline' />

            <div className='menu-container'>
                <div className='items-wrapper'>

                    <div className={`menu-item`} onClick={ () => { navigate('/home') }}>
                        <div className='icon-wrapper box-shadow'>
                            <ArrowLeft className='back' />
                        </div>

                        <div className='menu-title'>Main Dashboard</div>
                    </div>

                    <div className='banner-org'>
                        <img src={organizer.banner} alt='org banner' className='box-shadow' />
                        <div className='orgName'>You: {profile.name}</div>
                        <div className='orgName'>Organizer: {organizer.name}</div>
                    </div>

                    {
                        profile.visible && <div className={`menu-item ${ active === 'profile' ? 'active box-shadow' : '' }`} onClick={ () => { window.location.href = ('/settings?page=profile') }}>
                        <img src={profile.picture} className='icon' alt='pic' />

                        <div className='menu-title'>Profile</div>
                    </div>
                    }

                    <div className={`menu-item ${ active === 'org' ? 'active box-shadow' : '' }`} onClick={ () => { window.location.href = ('/settings?page=org') }}>
                    <img src={organizer.picture} className='icon' alt='pic' />

                        <div className='menu-title'>Organizer</div>
                    </div>

                    <div className={`menu-item ${ active === 'teams' ? 'active box-shadow' : '' }`} onClick={ () => { window.location.href = ('/settings?page=teams') }}>
                        <div className='icon-wrapper box-shadow'>
                            <Teams className='teams' />
                        </div>

                        <div className='menu-title'>Teams</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsDashBoardNavBar;
