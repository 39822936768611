import React from 'react';

import { Route, Routes } from "react-router-dom";

import Home from './Routes/Home';
import Sales from './Routes/Sales';
import SignUp from './Routes/SignUp';
import Register from './Routes/Register';
import Login, { LoginTokenRedirect } from './Routes/Login';
import DashBoard from './Routes/Dashboard';
import SignOut from './Routes/SignOut';
import Events from './Routes/Events';
import Billing from './Routes/Billing';
import Settings from './Routes/Settings';

function App() {
  return (
    <Routes>
      <Route path='/' element={ <Home /> } />
      <Route path='/sales' element={<Sales /> } />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/register' element={<Register />} />
      <Route path='/login'>
        <Route path='' element={<Login />} />
        <Route path=':token' element={<LoginTokenRedirect />} />
      </Route>
      <Route path='/sign/out' element={<SignOut />} />

      <Route path='/home' element={<DashBoard />} />
      <Route path='/events' element={<Events />} />
      <Route path='/billing' element={<Billing />} />
      <Route path='/settings' element={<Settings />} />
    </Routes>
  );
}

export default App;
