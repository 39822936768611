import { useState } from 'react';

import { joinWaitlist as jW, fetchGraphQl, JoinWaitlistInput } from '../utils';

export const useJoinWaitList = () => {
    const [ state, setState ] = useState({ loading: false, error: false, reload: false });

    return {
        ...state,
        joinWaitlist: async ( opt: JoinWaitlistInput ) => {
            setState( prev => ({ ...prev, loading: true }));

            const res = await fetchGraphQl( jW, { waitlistInput: opt });

            setState( prev => ({ ...prev, error: res.errors !== undefined, reload: true, loading: false }));

            setTimeout( () => setState( prev => ({...prev, reload: false })));
        }
    }
}