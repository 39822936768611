// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible {
  border-radius: 1vw;
  padding: 0.2vw;
  display: flex;
  flex-direction: column; }
  .collapsible.active .arrow {
    transform: rotate(90deg) !important; }
  .collapsible .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer; }
    .collapsible .header .arrow {
      width: 3vw;
      height: 3vw;
      transform: rotate(-90deg);
      margin-top: auto;
      margin-bottom: auto; }
  .collapsible .content {
    width: 70vw;
    margin-right: auto;
    margin-left: auto; }
`, "",{"version":3,"sources":["webpack://./src/Components/Collapsible/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,sBAAsB,EAAA;EAJ1B;IAQY,mCAAmC,EAAA;EAR/C;IAaQ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe,EAAA;IAhBvB;MAmBY,UAAU;MACV,WAAW;MACX,yBAAyB;MACzB,gBAAgB;MAChB,mBAAmB,EAAA;EAvB/B;IA4BQ,WAAW;IACX,kBAAkB;IAClB,iBAAiB,EAAA","sourcesContent":[".collapsible {\n    border-radius: 1vw;\n    padding: 0.2vw;\n    display: flex;\n    flex-direction: column;\n\n    &.active {\n        .arrow {\n            transform: rotate(90deg) !important;\n        }\n    }\n\n    .header {\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        cursor: pointer;\n\n        .arrow {\n            width: 3vw;\n            height: 3vw;\n            transform: rotate(-90deg);\n            margin-top: auto;\n            margin-bottom: auto;\n        }\n    }\n\n    .content {\n        width: 70vw;\n        margin-right: auto;\n        margin-left: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
