// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar .logo {
  background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
  font-size: 3vw !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  cursor: pointer; }

.navbar .underline {
  height: 0.14vw;
  background: linear-gradient(to right, #FFFFFF, #868686 48%, #FFFFFF);
  border-radius: 1vw;
  margin-bottom: 2vw;
  width: 13vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw; }
`, "",{"version":3,"sources":["webpack://./src/Components/NavBar/index.scss"],"names":[],"mappings":"AAAA;EAEQ,oEAAoE;EACpE,yBAAyB;EACzB,2BAA2B;EAC3B,8BAA8B;EAC9B,yBAAyB;EACzB,0BAA0B;EAC1B,eAAe,EAAA;;AARvB;EAYQ,cAAc;EACd,oEAAqE;EACrE,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,oBAAoB,EAAA","sourcesContent":[".navbar {\n    .logo {\n        background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);\n        font-size: 3vw !important;\n        margin-top: auto !important;\n        margin-bottom: auto !important;\n        margin-left: 0 !important;\n        margin-right: 0 !important;\n        cursor: pointer;\n    }\n    \n    .underline {\n        height: 0.14vw;\n        background: linear-gradient(to right, #FFFFFF, #868686 48%, #FFFFFF );\n        border-radius: 1vw;\n        margin-bottom: 2vw;\n        width: 13vw;\n        margin-right: auto;\n        margin-left: auto;\n        margin-top: 0.5vw;\n        margin-bottom: 0.5vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
