import './index.scss';
import React from 'react';

import MainDashBoardNavBar from '../../Components/NavBar/DashBoard/Main';
import UnReleased from '../../Components/UnReleased';

const Billing: React.FC = () => {
    return (
        <div className='BillingPage MainPage'>
            <MainDashBoardNavBar />
            <UnReleased date={new Date('01/13/2025')} />
        </div>
    )
}

export default Billing;
