import React from 'react';

type ArrowLeftProps = {
    fill?: string;
    className?: string;
    onClick?: ( e: any ) => void;
}

const ArrowLeft: React.FC<ArrowLeftProps> = ({ fill, className, onClick }) => {
    return (
        <svg height="800px" width="800px" className={className} onClick={onClick} version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"  xmlSpace="preserve">
            <g>
                <path fill={fill} d="M479.568,159.192H294.751l22.54-96.459c2.094-8.92-1.451-18.198-8.937-23.475
                    c-7.488-5.268-17.419-5.463-25.111-0.496L10.34,235.768C3.846,240.461,0,247.987,0,256.001c0,8.013,3.846,15.539,10.34,20.232
                    L283.243,473.24c7.692,4.965,17.623,4.771,25.111-0.506c7.486-5.267,11.021-14.546,8.937-23.465l-22.54-96.459h184.817
                    c17.905,0,32.432-14.518,32.432-32.433V191.623C512,173.708,497.474,159.192,479.568,159.192z"/>
            </g>
        </svg>
    );
}

export default ArrowLeft;
