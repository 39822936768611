import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createEvent, fetchGraphQl } from '../../utils';

export type AllEventsPageResponse = {
    id: string;
    title: string;
    banner: string;
    visibility: "Public" | "Private";
    views: number;
    dateCreated: number;
    eventDate: {
        startDate: number;
        endDate: number;
    }
}

const randomEvents = async ( length : number = 50 ) => {
    let gallery = [
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/b3066bfe-5474-402c-a2e0-eb0e6128375a.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/65992965-a999-486f-953a-d234abe13cb2.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/99c65391-fdb6-4d1b-8272-2ef4b1cfec0d.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/1d3aa0fb-784e-41a7-bbba-5fe8efb8ce89.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/0b456afa-9589-4225-b1ca-d176b9c27b53.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/36eb730e-f748-47ca-b2dd-2d84df2cfeec.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/18b14e1f-411d-4d36-82d3-b76a5dd18e58.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/ca5f864d-2cb8-457a-bf67-66361a1263ce.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/7e97770c-de7c-46e0-9d3b-47e3d23cb654.jpeg",
        "https://cravings-media.nyc3.digitaloceanspaces.com/events/gallery/image/jpeg/66e8a6af-0c43-42bb-adda-71b4287a6fc8.jpeg"
    ];

    let events: AllEventsPageResponse[] = [];

    for ( let i = 0; i < length; i++ ) {
        events.push({
            id: String(i),
            title: `Event ${i+1}`,
            banner: gallery[Math.floor(Math.random() * gallery.length)],
            visibility: Math.random() > 0.5? 'Public' : 'Private',
            views: Math.floor(Math.random() * 1000),
            dateCreated: Date.now() - Math.floor(Math.random() * 10000000),
            eventDate: {
                startDate: Date.now() - Math.floor(Math.random() * 10000000),
                endDate: Date.now() + Math.floor(Math.random() * 10000000),
            }
        })
    }

    return events;
}

export const useEvents = () => {
    const navigate = useNavigate();
    
    let [ data, setData ] = useState<AllEventsPageResponse[]>([]);

    const [ fetchData, setFetchData ] = useState<AllEventsPageResponse[]>([]);

    const [ lastIndex, setLastIndex ] = useState<number>(0);
    
    const [ loadMore, setLoadMore ] = useState<boolean>(true);

    let [ loading, setLoading ] = useState<boolean>( false );

    const [ createEventActive, setCreateEventActive ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);
    const [ title, setTitle ] = useState<string>('');
    const [ description, setDescription ] = useState<string>('');

    const pageSize = 7;

    useEffect( () => {
        (
            async () => {
                setLoading( true );
                setFetchData( await randomEvents( 500 ) );
                setLoading( false );
            }
        )()
    }, []);

    useEffect( () => {
        (
            async () => {

                if ( fetchData.length === 0 ) return ;

                setLoading( true );

                await paginationRequest();

                setLoading( false );
            }
        )();
    }, [ fetchData ]);
    
    
    const paginationRequest = async ( ) => {
        setLoading( true );

        let endIndex = lastIndex + pageSize;

        let newFetchData = fetchData.slice( lastIndex, endIndex );

        setData( [...data,...newFetchData ] );

        setLastIndex( endIndex );

        setLoadMore( loadMore );

        setLoading( false );

        return {
            endIndex,
            loadMore: fetchData.length - 1 > endIndex
        };
    }

    return  {
        createEventActive,
        setCreateEventActive,
        loading,
        data,
        loadMore,
        paginationRequest,
        setTitle,
        setDescription,
        error,

        createEvent: async ( token: string ) => {
            if ( title.length <= 0 || description.length <= 0 ) return setError("Please fill out form.");

            // Send to server

            setLoading( true );

            let res = await fetchGraphQl(createEvent, { token, title, description })

            if ( res.errors ) return setError("Problem creating event");

            setError( null );
            setLoading( false );

            navigate(`/events/${res.data.createEvent.id}`);
            
        }
    }
}
