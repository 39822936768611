// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-container .options {
  background: white;
  width: 14vw;
  height: 2vw;
  border-radius: 0.5vw;
  padding: 0.5vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 1px solid gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .dropdown-container .options:hover {
    background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);
    border: none;
    color: white; }

.dropdown-container .selector {
  width: 14vw;
  height: 2vw;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0.5vw;
  padding-left: 1vw;
  padding-right: 1vw; }
  .dropdown-container .selector .placeholder {
    margin-right: auto;
    margin-left: auto;
    color: #8b8b8b; }
  .dropdown-container .selector .arrow {
    width: 1.5vw;
    height: 1.5vw;
    transform: rotate(-90deg); }
    .dropdown-container .selector .arrow.active {
      transform: rotate(90deg); }
`, "",{"version":3,"sources":["webpack://./src/Components/DropDown/index.scss"],"names":[],"mappings":"AAAA;EAEQ,iBAAiB;EACjB,WAAW;EACX,WAAW;EACX,oBAAoB;EACpB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe,EAAA;EAbvB;IAgBY,oEAAoE;IACpE,YAAY;IACZ,YAAY,EAAA;;AAlBxB;EAuBQ,WAAW;EACX,WAAW;EACX,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,kBAAkB,EAAA;EAjC1B;IAoCY,kBAAkB;IAClB,iBAAiB;IACjB,cAAyB,EAAA;EAtCrC;IA0CY,YAAY;IACZ,aAAa;IACb,yBAAyB,EAAA;IA5CrC;MA+CgB,wBAAwB,EAAA","sourcesContent":[".dropdown-container {\n    .options {\n        background: white;\n        width: 14vw;\n        height: 2vw;\n        border-radius: 0.5vw;\n        padding: 0.5vw;\n        padding-left: 1vw;\n        padding-right: 1vw;\n        border: 1px solid gainsboro;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        cursor: pointer;\n\n        &:hover {\n            background-image: linear-gradient(to bottom right, #F34A4A, #AA49CC);\n            border: none;\n            color: white;\n        }\n    }\n\n    .selector {\n        width: 14vw;\n        height: 2vw;\n        border-radius: 0.5vw;\n        display: flex;\n        flex-direction: row;\n        justify-content: space-between;\n        align-items: center;\n        cursor: pointer;\n        padding: 0.5vw;\n        padding-left: 1vw;\n        padding-right: 1vw;\n\n        .placeholder {\n            margin-right: auto;\n            margin-left: auto;\n            color: rgb(139, 139, 139);\n        }\n\n        .arrow {\n            width: 1.5vw;\n            height: 1.5vw;\n            transform: rotate(-90deg);\n\n            &.active {\n                transform: rotate(90deg);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
