import './index.scss'

import React, { useState } from "react";
import ArrowLeft from "../svgs/ArrowLeft";

type CollapsibleProps = {
    children: React.ReactNode;
    title: string;
    classes?: {
        container?: string;
        title?: string;
        arrow?: string;
    }
}

const Collapsible: React.FC<CollapsibleProps> = ({ children, title, classes }) => {
    const [ active, setActive ] = useState<boolean>(false);

    return (
        <div className={`collapsible box-shadow ${ active ? 'active' : '' } ${classes?.container}`}>
            <div className='header' onClick={ () => setActive( !active ) } >
                <div className={`title ${classes?.title}`}>{title}</div>

                <ArrowLeft className={`arrow ${classes?.arrow}`} />
            </div>

            { active && <div className='content'>{children}</div> }
        </div>
    )
}

export default Collapsible;
