import './index.scss';

import React, { useState, useEffect } from 'react';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import UnSignedNavBar from '../../Components/NavBar/Unsigned';
import { useJoinWaitList } from '../../hooks';
import { JoinWaitlistInput } from '../../utils';

const SignUp: React.FC = () => {
    const [ props, setProps ] = useState<JoinWaitlistInput>({ first_name: '', last_name: '', email: '', phone_number: '' });
    const { reload, joinWaitlist } = useJoinWaitList();

    useEffect( () => {
        if ( reload === true ) window.location.reload();
    }, [ reload ]);

    const v1Updates = [
        {
            title: "Release Home Page",
            description: "Two days long process of designing, editing, and finally programming of the home page. Home page was meant to tell the story of our product and where we want it to go",
            date: "Aug 26, 2024"
        },

        {
            title: "Release Sales Page",
            description: "Sales Page allow future users to react out to us directly. This is perfect as we try to start building a relationship between us and our user base. Allowing us to be more user friendly and relatable.",
            date: "Aug 27, 2024"
        },

        {
            title: "Release Waitlist Page",
            description: "Waitlist page gives our user base a way to stay up to date with all platform updates as we approach release of our official platform. This will help them understand how our platform works, giving them email updates about upcomiong releases and when the platform will finally be released. While also giving them access to our discord community plus more.",
            date: "Sep 3, 2024"
        },

        {
            title: "Synced User & Organizer Plaform",
            description: "Started syncing user and organizer platform, so it can follow more smoothly and dynamically. Allowing it to feel like all one site, as this feeling will help us become more user friendly and trustworthy to both organizers and users.",
            date: "Sep 11, 2024"
        },

        {
            title: "Release Authentication & Session",
            description: "This new update allows us to authenticate our organizers properly and managing sessions cross platform. Which makes for a smoot experience for organziers! While allowing our devs to now start building up the application properly without worries and withe ease.",
            date: "Sep 11, 2024"
        },

        {
            title: "Platform Countdown Release",
            description: "Say hello to our feature countdown so now organizers can now onboard on our platform but only certain parts of the site will be available to them. And we can build the hype like this with the steady release. We will also allow certain organizers to not see the countdown, to give certain organizers special access, before our official lunch!!!",
            date: "Sep 24, 2024"
        },

        {
            title: "Release Date Change",
            description: "The release schedule has been officially updated due to development work, the addition of new features, and the resolution of unexpected bugs. The updated release dates are as follows: Events Dashboard on December 16th, Main Dashboard on December 30th, and Billing Dashboard on January 13th. The final release date for all features is January 27th. These updates will include features designed to make the planning, management, and execution of events ten times easier for organizers.",
            date: "Nov 30, 2024"
        },

        {
            title: "First Release of Version 1",
            description: "This initial release comes packed with updates and bug fixes. It comes with a redesign User Platform with everything being white themed. Also with the official release of our organizer settings page and events dashboard. With the start of 2025 we are excited for all the year has in store.",
            date: "Jan 1st, 2025"
        }
    ]

    return (
        <div className='SignUpPage'>
            <UnSignedNavBar />

            <div className='content'>
                <div className='feature-updates'>
                    <div className='header'>V.1 Updates & News</div>
                    <div className='sub-header'>Everything you need to know about v.1</div>

                    <div className='container'>
                        {
                            v1Updates.map( ( update, index ) => (
                                <div className='update-card'>
                                    <div className='date-connector-card'>
                                        <div className='date-card'>
                                            <div className='date box-shadow'>{ update.date }</div>
                                            <div className='bubble' />
                                        </div>

                                        { index !== v1Updates.length - 1 && <div className='line-connector' /> }
                                    </div>

                                    <div className={`content ${ ( index + 1 ) % 2 === 0 ? 'opp' : '' } box-shadow`}>
                                        <div className='title'>{ update.title }</div>
                                        <div className='desc'>{ update.description }</div>
                                    </div>
                                </div>
                            ) )
                        }
                    </div>
                </div>

                <div className='waitlist-container box-shadow'>
                    <div className='header'>Join Waitlist</div>
                    <div className='sub-header'>Working around the clock to build a software like no other. Don't believe us? Join our waitlist and get updates with new releases.</div>
                
                    <div className="link">
                        <div className="label">
                            <label htmlFor="first_name">First Name</label>
                            <input type="text" className="form-control-input" name="first_name" 
                                onChange={ (e) => setProps( prev => ({ ...prev, first_name: e.target.value }))}
                            />
                        </div>

                        <div className="label">
                            <label htmlFor="last_name">Last Name</label>
                            <input type="text" className="form-control-input" name="last_name" 
                                onChange={ (e) => setProps( prev => ({ ...prev, last_name: e.target.value }))}
                            />
                        </div>
                    </div>

                    <div className="label">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control-input" name="email" 
                            onChange={ (e) => setProps( prev => ({ ...prev, email: e.target.value }))}
                        />
                    </div>

                    <div className="label">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input type="telle" className="form-control-input" name="phone_number" 
                            onChange={ (e) => setProps( prev => ({ ...prev, phone_number: e.target.value }))}
                        />
                    </div>

                    <div className='submit'
                        onClick={
                            () => {
                                // Veryify Props then send to server

                                if (
                                    props.first_name.length <= 0
                                    || props.last_name.length <= 0
                                    || props.phone_number.length <= 0
                                    || !props.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                                ) return alert("Please fill out form.");

                                joinWaitlist(props);
                            }
                        }
                    >
                        <div className='btn-text'>Join Now</div>
                        <BsFillArrowRightCircleFill className="btn-ico" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;
