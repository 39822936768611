import React, { useEffect, useImperativeHandle } from 'react';
import { useLogin } from '../hooks';
import { OrganizerProfileInformation, reLogin } from '../utils';

type AuthenticatedPageProps = {
    children: React.ReactNode;
    setReady: ( val: boolean ) => void;
}

export type AuthenticatedPageRef = {
    getLoggedIn: () => boolean;
    getLoading: () => boolean;
    getError: () => string;
    getToken: () => string | null;
    getUser: () => OrganizerProfileInformation | null;
    signOut: () => void;
    relogin: (nav?: boolean) => Promise<void>;
}

const AuthtenticatedPage = React.forwardRef<AuthenticatedPageRef, AuthenticatedPageProps>( ( { children, setReady }, ref ) => {
    const { loading, loggedIn, error, token, user, relogin, signOut } = useLogin();
    
    useEffect( () => {
        (
            async () => {
                await relogin(true);
                setReady(true);
            }
        )()
    }, []);

    useImperativeHandle( ref, () => ({
        getLoggedIn: () => loggedIn,
        getLoading: () => loading,
        getError: () => error,
        getToken: () => token,
        getUser: () => user,
        signOut,
        relogin
    }));

    return (
        <>
            {children}
        </>
    )
})

export default AuthtenticatedPage;
