import React, { useState } from 'react';
import Collapsible from '../../Components/Collapsible';
import PopUp from '../../Components/PopUp';

type AccountDeletionProps = {
    deleteMember: () => Promise<void>;
    orgName: string;
}

const AccountDeletion: React.FC<AccountDeletionProps> = ({ orgName, deleteMember }) => {
    const [active, setActive] = useState<boolean>(false);
    const [confirmationText, setConfirmationText] = useState<string>("");
    const [error, setError] = useState("");

    return (
        <div className="collapsible-account-deletion">

            <Collapsible title="Account Deletion">
                <div className='description'>
                    Tread carefully here. You delete your account, you lose all your data, your won't be able to access events, pages or even teams, and we can’t retrieve any of it for you. Proceed only if you are sure you want remove yourself from this organizer account.
                </div>

                <div
                    className="button box-shadow"
                    onClick={() => setActive(true)}
                >
                    Delete Account
                </div>
            </Collapsible>
            
            <PopUp active={active} setActive={setActive}>
                <div className="box-shadow confirm-popup">
                    <p>Are you sure you want to delete yourself from <strong>{orgName}?</strong> All of the data related to the organizer workspace will be permanently removed. This action cannot be undone.</p>
                    
                    <p>
                        To delete your account, please type <strong>"delete my account"</strong> below:
                    </p>
                        
                    <input
                        className="account-deletion box-shadow"
                        type="text"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                        placeholder="Type here..."
                    />
                        
                    {error && <p className="error">{error}</p>}
                        
                    <br></br>
                        
                    <div className="button" onClick={ 
                        confirmationText === "delete my account"
                            ? () => deleteMember()
                                : () => { setError("You must type 'delete my account' to confirm.") }
                    }>
                        Confirm Deletion
                    </div>
                </div>
            </PopUp>

        </div>
    );
}

export default AccountDeletion;