export const changeOrganizerPassword = `
mutation ChangeOrganizerPassword($token: String!) {
  changeOrganizerPassword(token: $token)
}
`;

export const changeOrganizerMemberPassword = `
mutation changeOrganizerMemberPassword($token: String!) {
  changeOrganizerMemberPassword(token: $token)
}
`;
