export const addTeamMember = `
mutation AddTeamMember($args: CreateOrganizerMemberInput!, $token: String!) {
  addTeamMember(args: $args, token: $token)
}
`;

export const getOrganizerSettingsPage = `
query GetOrganizerSettingsPage($token: String!) {
  getOrganizerSettingsPage(token: $token) {
    isOrg
    user {
      id
      email
      name
      phoneNumber
      profilePicture
      title
    }
    organizer {
      id
      email
      banner
      location
      orgName
      phoneNumber
      profilePicture
    }
    teams {
      accepted {
        id
        joinedDate
        name
        type
        email
        profilePicture
      }

      pending {
        id
        joinedDate
        name
        type
        email
        profilePicture
      }
    }
  }
}
`;

export const saveOrganizerSettings = `
mutation SaveOrganizerSettings($args: OrganizerSettingsUpdateInput!, $token: String!) {
  saveOrganizerSettings(args: $args, token: $token) {
    error {
      code
      message
    }
    response
    organizer {
      id
      email
      banner
      location
      orgName
      phoneNumber
      profilePicture
    }
  }
}
`;

export const saveOrganizerMemberSettings = `
mutation saveOrganizerMemberSettings($args: OrganizerMemberSettingsUpdateInput!, $token: String!) {
  saveOrganizerMemberSettings(args: $args, token: $token) {
    error {
      code
      message
    }
    response
    member {
      id
      email
      name
      phoneNumber
      profilePicture
    }
  }
}
`;


export const removeTeamMember = `
mutation RemoveTeamMember($teamMemberId: String!, $token: String!) {
  removeTeamMember(teamMemberId: $teamMemberId, token: $token)
}`;

export const updateTeamMemberTitle = `
mutation UpdateTeamMemberTitle($newTitle: String!, $teamMemberId: String!, $token: String!) {
  updateTeamMemberTitle(newTitle: $newTitle, teamMemberId: $teamMemberId, token: $token)
}`;

export const resendOrganizerTeamInviteToken = `
query Query($orgTeamId: String!, $token: String!) {
  resendOrganizerTeamInviteToken(orgTeamId: $orgTeamId, token: $token)
}
`;
