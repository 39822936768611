// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-up {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
  .pop-up.active {
    display: block; }
  .pop-up .background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.25;
    z-index: 5;
    border: 1px solid black; }
  .pop-up .content {
    z-index: 10;
    position: relative;
    width: fit-content;
    height: fit-content; }
`, "",{"version":3,"sources":["webpack://./src/Components/PopUp/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,eAAe;EACf,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO,EAAA;EANX;IASQ,cAAc,EAAA;EATtB;IAaQ,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,UAAU;IACV,uBAAuB,EAAA;EArB/B;IAyBQ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB,EAAA","sourcesContent":[".pop-up {\n    display: none;\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n\n    &.active {\n        display: block;\n    }\n\n    .background {\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100%;\n        height: 100%;\n        background: #000000;\n        opacity: 0.25;\n        z-index: 5;\n        border: 1px solid black;\n    }\n\n    .content {\n        z-index: 10;\n        position: relative;\n        width: fit-content;\n        height: fit-content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
