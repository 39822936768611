import './index.scss';

import React, { useState } from 'react';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import UnSignedNavBar from '../../Components/NavBar/Unsigned';

import { useRegister } from '../../hooks';

const Register: React.FC = () => {
             
    const [ props, setProps ] = useState<{ orgName: string, email: string, password: string }>({ orgName: '', email: '', password: '' });
    const { register, navigate } = useRegister();

    return (
        <div className='RegisterPage'>
            <UnSignedNavBar />

            <div className='content'>
                <div className='photo-slogan'>
                    <div className='slogan logo'>Built By Organizer For Organizers.</div>
                    <img src='/register-page.png' alt='community' />
                </div>

                <div className='register-container box-shadow'>
                    <div className='header'>Join Our Community</div>
                    <div className='sub-header logo'>Join Our Community Of Unstoppable Organizers Planning Event All Across The World.</div>
                
                    <input type="text" className="form-control-input box-shadow" name="orgName"  placeholder='Organizer Name'
                        onChange={ (e) => setProps( prev => ({ ...prev, orgName: e.target.value }))}
                    />

                    <input type="email" className="form-control-input box-shadow" name="email" placeholder='Email'
                        onChange={ (e) => setProps( prev => ({ ...prev, email: e.target.value }))}
                    />

                    <input type="password" className="form-control-input box-shadow" name="password" placeholder='Password'
                        onChange={ (e) => setProps( prev => ({ ...prev, password: e.target.value }))}
                    />

                    <div className='submit'
                        onClick={
                            () => {
                                // Veryify Props then send to server

                                if (
                                    props.orgName.length <= 0
                                    || props.password.length <= 0
                                    || !props.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
                                ) return alert("Please fill out form.");

                                register( props.orgName, props.email, props.password, true, true );
                            }
                        }
                    >
                        <div className='btn-text'>Register Now</div>
                        <BsFillArrowRightCircleFill className="btn-ico" />
                    </div>

                    <div className='login-text logo' onClick={ () => navigate(`/login`)}>
                        Already have an account? Sign In
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
