import React, { useState } from 'react';
import PopUp from '../../Components/PopUp';
import DropDown from '../../Components/DropDown';

type addTeamMemberProps = {
    active: boolean;
    setActive: ( val: boolean ) => void;
    createTeamMember: ( name: string, email: string, phoneNumber: string, role: string ) => Promise<void>;
}

export const AddTeamMember : React.FC<addTeamMemberProps> = ({ active, setActive, createTeamMember }) => {

    const [ name, setName ] = useState<string>('');
    const [ email, setEmail ] = useState<string>('');
    const [ phoneNumber, setPhoneNumber ] = useState<string>('');
    const [ role, setRole ] = useState<"Admin" | "Member" | "Guest">('Guest');

    return (
        <PopUp active={active} setActive={setActive}>
            <div className='addTeamMember box-shadow'>
                <div className='title'>Add Member</div>

                <input type="text" className="form-control-input box-shadow" name="name" placeholder='Member Name' 
                    onChange={ e => setName( e.target.value )}
                />

                <input type="text" className="form-control-input box-shadow" name="email" placeholder='Email' 
                    onChange={ e => setEmail( e.target.value )}
                />

                <input type="text" className="form-control-input box-shadow" name="phoneNumber" placeholder='Phone Number' 
                    onChange={ e => setPhoneNumber( e.target.value )}
                />

                <DropDown 
                    options={[
                        { val: "Admin", text: "Admin" },
                        
                        { val: "Member", text: "Member" },

                        { val: "Guest", text: "Guest" }
                    ]}

                    placeholder='Role'

                    onChange={ ( selected ) => setRole( selected.val ) }
                />

                <div className='button' onClick={ () => createTeamMember( name, email, phoneNumber, role ) }>Add</div>
            </div>
        </PopUp>
    )
}

