import './index.scss';

import React, { useState } from 'react';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import UnSignedNavBar from '../../Components/NavBar/Unsigned';

import { useLogin } from '../../hooks';
export * from './LoginTokenRedirect';

const Login: React.FC = () => {
             
    const [ props, setProps ] = useState<{ orgName: string, email: string, password: string }>({ orgName: '', email: '', password: '' });
    const { login, navigate } = useLogin();

    return (
        <div className='LoginPage'>
            <UnSignedNavBar />

            <div className='content'>
                <div className='photo-slogan'>
                    <div className='slogan logo'>Built By Organizer For Organizers.</div>
                    <img src='/login-page.png' alt='community' />
                </div>

                <div className='register-container box-shadow'>
                    <div className='header'>Welcome Back</div>
                    <div className='sub-header logo'>We Really Really Missed You!!!</div>

                    <input type="text" className="form-control-input box-shadow" name="email" placeholder='Organizer Name'
                        onChange={ (e) => setProps( prev => ({ ...prev, orgName: e.target.value }))}
                    />

                    <input type="email" className="form-control-input box-shadow" name="email" placeholder='Email'
                        onChange={ (e) => setProps( prev => ({ ...prev, email: e.target.value }))}
                    />

                    <input type="password" className="form-control-input box-shadow" name="password" placeholder='Password'
                        onChange={ (e) => setProps( prev => ({ ...prev, password: e.target.value }))}
                    />

                    <div className='submit'
                        onClick={
                            () => {
                                // Veryify Props then send to server

                                if (
                                    props.email.length <= 0
                                    || props.password.length <= 0
                                ) return alert("Please fill out form.");

                                login( props.orgName, props.email, props.password )
                            }
                        }
                    >
                        <div className='btn-text'>Login Now</div>
                        <BsFillArrowRightCircleFill className="btn-ico" />
                    </div>

                    <div className='login-text logo' onClick={ () => navigate(`/register`)}>
                        Don't have an account? Register
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
