import { useState } from "react";

import { makeContact, fetchGraphQl, MakeContactInput } from '../utils';


export const useMakeContact = () => {
    const [ state, setState ] = useState({ loading: false, error: false, reload: false });

    return {
        ...state,
        makeContact: async ( opt: MakeContactInput ) => {
            setState(prev => ({ ...prev, loading: true }));

            const res = await fetchGraphQl(makeContact, { contactInput: opt});
        
            setState(prev => ({ ...prev, error: res.errors !== undefined, reload: true, loading: false }));

            setTimeout( () => setState(prev => ({...prev, reload: false })), 2000);
        }
    }
}
