import React from "react";

import { SettingsDashBoardNavBarProps } from "../../Components/NavBar/DashBoard/Settings";
import FileUpload from "../../Components/svgs/FileUpload";
import ImageUploader from "../../Components/ImageUploader";
import AccountDeletion from "./AccountDeletion"

export const ProfileSettings: React.FC<SettingsDashBoardNavBarProps<{
    phoneNumber: string;
    email: string;
    }> & {
        deleteMember: (id: string) => Promise<void>;
        userId: string;
    }> = ( props ) => {   
    
    return (
        <div className="profile contents">
            <div className='banner'>
                <ImageUploader 
                    preview={(
                        <img
                            src={props.organizer.banner}
                            alt="banner"
                            className="banner box-shadow"
                        />
                    )}
                    clickable={ false }
                    handleImageUpload={ async () => {} } // Should not be called, because can't upload banner from team profile
                />
            </div>

            <div className="profile-info">
                <div className="pic-container">
                    <div className='profile-cont'>
                         
                        <ImageUploader 
                            preview={(
                                <img src={props.profile.picture} alt="profile" className="profile" />
                            )} 
                            clickable={ props.profile.editable }
                            handleImageUpload={props.profile.uploadProfilePicture}
                        >
                            { props.profile.editable && <FileUpload className='icon' /> }
                        </ImageUploader>

                    </div>

                    <div className="username">{props.profile.name}</div>
                </div>

                <div className="buttons">
                    <div className="reset"
                        onClick={props.profile.changeMemberPassword}>Reset Password</div>
                    <div className="save"
                        onClick={props.profile.saveOrganizerMemberSettings}
                    >Save</div>
                </div>
            </div>

            <div className="header">Profile Information</div>

            <div className="form-control box-shadow">
                <div className="link">
                    <div className="label">
                        <label htmlFor="first_name">Name</label>
                        <input
                            type="text"
                            className="form-control-input box-shadow"
                            name="first_name"
                            placeholder={props.profile.name}
                            value={props.profile.name}
                            readOnly={!props.profile.editable}
                            onChange={ (e) => props.profile.editMember({ name: e.target.value })}
                        />
                    </div>

                    <div className="label">
                        <label htmlFor="last_name">Phone Number</label>
                        <input
                            type="text"
                            className="form-control-input box-shadow"
                            name="last_name"
                            placeholder={props.profile.phoneNumber}
                            value={props.profile.phoneNumber}
                            readOnly={!props.profile.editable}
                            onChange={ (e) => props.profile.editMember({ phoneNumber: e.target.value })}
                        />
                    </div>
                </div>

                <div className="label">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        className="form-control-input box-shadow"
                        name="email"
                        placeholder={props.profile.email}
                        value={props.profile.email}
                        readOnly={!props.profile.editable}
                        onChange={ (e) => props.profile.editMember({ email: e.target.value })}
                    />
                </div>
            </div>

            <AccountDeletion deleteMember={ 
                    async () =>  {
                        await props.deleteMember(props.userId);
                        window.location.reload() // logs user out
                    }
                } 
                orgName={ props.organizer.name }
            />
        </div>
    );
};