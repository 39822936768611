import './index.scss';
import React, { useEffect, useRef, useState } from 'react';

import AuthtenticatedPage, { AuthenticatedPageRef } from '../../Components/AuthenticatedPage';

import MainDashBoardNavBar from '../../Components/NavBar/DashBoard/Main';
import { EventCard } from './EventCard';
import Plus from '../../Components/svgs/Plus';
import PopUp from '../../Components/PopUp';
import { useEvents } from '../../hooks';


const Events: React.FC = () => {
    const authPageRef = useRef<AuthenticatedPageRef>(null);
    const [ authReady, setAuthReady ] = useState<boolean>(false);
    
    const { setCreateEventActive, loading, data, loadMore, createEventActive, paginationRequest, error, setTitle, setDescription, createEvent } = useEvents();

    useEffect( () => {
        authPageRef.current && console.log( authPageRef.current.getToken() || '' );
    }, [ authReady ]);

    return (
        <AuthtenticatedPage ref={authPageRef} setReady={ () => setAuthReady(true) }>
            <div className='EventsPage MainPage'>
                <MainDashBoardNavBar />
                <div className='content'>
                    <div className='hover-fixed-content'>
                        <div className='header box-shadow'>
                            <div className='title'>All Events</div>

                            <div className='button' onClick={ () => setCreateEventActive( true ) }>
                                <Plus />
                                <div className='title logo'>Create Event</div>
                            </div>
                        </div>

                        <div className='table'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='name'>Name</div>
                                    <div className='visibility'>Visibility</div>
                                    <div className='view'>View</div>
                                    <div className='date-created'>Date Created</div>
                                    <div className='event-date'>Event Date</div>
                                </div>
                                <div className='divider' />
                            </div>
                        </div>
                    </div>

                    <div className='table'>
                        {
                            loading ? 
                            <div className='loading'>Loading...</div>
                            :
                            data.map( ( event, index ) => (
                                <EventCard {...event} lastIndex={ data.length - 1 === index } />
                            ))
                        }
                    </div>

                    { loadMore && <div className='loadmore' onClick={ paginationRequest }>Load More</div> }
                </div>

                <PopUp active={createEventActive} setActive={setCreateEventActive}>

                    <div className='addEvent box-shadow'>
                        <div className='title'>Create Event</div>

                        {error && <p className="error">{error}</p>}

                        <input type="text" className="form-control-input box-shadow" name="name" placeholder='Event Name' 
                            onChange={ e => setTitle( e.target.value )}
                        />

                        <input type="text" className="form-control-input box-shadow desc" name="description" placeholder='Description' 
                            onChange={ e => setDescription( e.target.value )}
                        />

                        <div className='button' onClick={ () => createEvent( authPageRef.current?.getToken() || '' ) }>Create</div>
                    </div>
                </PopUp>
            </div>
        </AuthtenticatedPage>
    )
}

export default Events;
