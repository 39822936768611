import './index.scss';
import React, { useState, useRef, useEffect } from 'react';

import AuthtenticatedPage, { AuthenticatedPageRef } from '../../Components/AuthenticatedPage';

import SettingsDashBoardNavBar, { UserTitle } from '../../Components/NavBar/DashBoard/Settings';

import { ProfileSettings } from './Profile';
import { OrganizerSettings } from './Org';
import { TeamsSettings } from './Teams';

import { useSettings } from '../../hooks';

const Settings: React.FC = () => {
    const authPageRef = useRef<AuthenticatedPageRef>(null);
    const [ authReady, setAuthReady ] = useState<boolean>(false);
    const [ page, setPage ] = useState<"profile" | "org" | "teams">("profile");

    const { 
        isOrg, user, organizer, 
        teams, pendingTeams, deleteMember, updateMember, createTeamMember, resendMemberInvitation,
        changeMemberPassword, editMember, saveOrganizerMemberSettings,
        editOrganizer, saveOrganizerSettings, changeOrganizerPassword, uploadBanner, uploadProfilePicture, 
        fetchData
    } = useSettings();

    let props = {
        profile: {
            visible: !isOrg,
            picture: user?.profilePicture,
            name: user?.name,
            title: user?.title,
            phoneNumber: user?.phoneNumber,
            email: user?.email,
            id: user?.id,
            banner: organizer.banner,
            editable: !isOrg,
            editMember,
            uploadProfilePicture: async ( img: File ) => await uploadProfilePicture( authPageRef.current?.getToken() || '', img, 'member' ),
            saveOrganizerMemberSettings: () => saveOrganizerMemberSettings( authPageRef.current?.getToken() || '' ),
            changeMemberPassword: () => changeMemberPassword( authPageRef.current?.getToken() || '' ),
        },

        organizer: {
            name: organizer.orgName,
            picture: organizer.profilePicture,
            banner: organizer.banner,
            editable: isOrg,
            email: organizer.email,
            location: organizer.location,
            phoneNumber: organizer.phoneNumber,
            editOrganizer,
            saveOrganizerSettings: () => saveOrganizerSettings( authPageRef.current?.getToken() || '' ),
            changeOrganizerPassword: () => changeOrganizerPassword( authPageRef.current?.getToken() || '' ),
            uploadBanner: async ( img: File ) => await uploadBanner( authPageRef.current?.getToken() || '', img ),
            uploadProfilePicture: async ( img: File ) => await uploadProfilePicture( authPageRef.current?.getToken() || '', img )
        },

        teams,
        pendingTeams,
        deleteMember: async ( id: string ) => {
            await deleteMember( id, authPageRef.current?.getToken() || '' );
            
            if ( id === user?.id ) await authPageRef.current?.relogin( true ); // Verifies token if the id and user id matches each other. If token is not verified then logs user out.
        },
        updateMember: async ( id: string, title: UserTitle ) => await updateMember( id, authPageRef.current?.getToken() || '', title ),
        resendMemberInvitation: async ( orgMemberId: string ) => await resendMemberInvitation( authPageRef.current?.getToken() || '', orgMemberId ),
        createTeamMember: async ( name: string, email: string, phoneNumber: string, role: string ) => await createTeamMember( authPageRef.current?.getToken() || '', name, email, phoneNumber, role )
    }

    useEffect( () => {
        authPageRef.current && fetchData( authPageRef.current.getToken() || '' );
    }, [ authReady ]);
    
    return (
        <AuthtenticatedPage ref={authPageRef} setReady={ () => setAuthReady(true) }>
            <div className='SettingsPage MainPage'>
                <SettingsDashBoardNavBar settings={{...props}} setPage={ ( page ) => setPage( page ) } />

                {
                    page === "profile" && props.profile.visible ? 
                        <ProfileSettings {...props} deleteMember={props.deleteMember} userId={user?.id}/>
                            : page === "org" ? (
                                <OrganizerSettings {...props} />
                            ) : <TeamsSettings {...props} />
                }
            </div>
        </AuthtenticatedPage>
    )
}

export default Settings;
